import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Card, Carousel, Button } from "antd"
import styled from "styled-components"
import { EyeOutlined } from "@ant-design/icons"

const StyledProjectCard = styled(Card)`
  width: 350px;
  border-radius: 10px;
  margin: 30px;
  :hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }

  & > ul {
    border-radius: 0px 0px 15px 10px;
    border-top: 0px;
    background-color: #202020;
  }
  & > ul > li {
    margin: 0px 0px 20px 0px;
  }
  background-color: #202020;
`

const StyledParentCard = styled(Card)`
  .ant-card-head {
    font-size: 4vw;
    font-family: "JosefinSans";
    text-align: center;
    @media only screen and (max-width: 800px) {
      font-size: 35px;
    }
  }
`

const cardHeaderStyle = {
  textAlign: "center",
  fontSize: "20px",
  color: "white",
}

const bodyStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
}

const imageStyle = {
  margin: "auto",
}

export const ProjectCard = props => {
  const projects = props.projects
  const images = props.allFiles
  const projectsWithImages = []

  projects.forEach(project => {
    const imagesArray = []
    project.projmedias.forEach(media => {
      const image = getImage(
        images.find(
          image =>
            image.name.toLowerCase() ===
            media.media[0].mediaPath.toLowerCase().replace(".jpg", "")
        )
      )
      imagesArray.push({ image, name: media.media[0].mediaPath })
    })
    projectsWithImages.push({
      name: project.projName,
      slug: project.fields.slug,
      description: project.projectDescription,
      id: project.projID,
      imagesArray,
    })
  })

  return (
    <StyledParentCard title={props.title} bodyStyle={bodyStyle}>
      {projectsWithImages.map(project => (
        <StyledProjectCard
          title={project.name}
          hoverable={true}
          headStyle={cardHeaderStyle}
          id={project.id}
          key={project.id}
          actions={[
            <div>
              <Link to={`/project/${project.slug}/`}>
                <Button icon={<EyeOutlined />} size="middle">
                  View Project
                </Button>
              </Link>
            </div>,
          ]}
        >
          <Carousel autoplay>
            {project.imagesArray.map(image => (
              <GatsbyImage
                image={image.image}
                alt={image.name}
                key={image.name}
                style={imageStyle}
              />
            ))}
          </Carousel>
        </StyledProjectCard>
      ))}
    </StyledParentCard>
  )
}
