import * as React from "react"
import { ProjectCard } from "../components/ProjectCard"
import { ComingSoon } from "../components/ComingSoon"

const ProjectsPage = ({ pageContext }) => {
  const projects = pageContext.projects
  const allImages = pageContext.projectImages
  return (
    <>
      {projects.length > 0 ? (
        <ProjectCard
          projects={projects}
          allFiles={allImages}
          title={pageContext.pageTitle}
        />
      ) : (
        <ComingSoon title={pageContext.pageTitle} />
      )}
    </>
  )
}

export default ProjectsPage
